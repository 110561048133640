import React from 'react'
import tw, { css } from 'twin.macro'

function FormSuccess() {
	return (
		<section tw=" my-6  lg:my-8 text-center w-4/5 max-w-screen-lg mx-auto h-full py-5 sm:py-20 px-5 sm:px-16 flex flex-col items-center gap-6 border">
			<h1 tw="text-2xl text-blue-500 md:text-3xl lg:text-4xl font-bold mt-4">
				Thank you for submitting your enquiry
			</h1>
			<p tw="text-sm  md:text-lg lg:text-xl text-gray-600">
				We will pass your details onto your nearest Elite Pool Builders and they
				will contact you very soon.
			</p>
		</section>
	)
}

export default FormSuccess
