import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
const windowGlobal = typeof window !== 'undefined' && window
const isBrowser = typeof window !== 'undefined'
/**
 * useCartWithLocalStorage - bind localStorage with state
 * get cart {Object} from `localStorage`
 * @returns {Promise}
 */
export const useCartWithLocalStorage = (key, defaultValue) => {
	const localStorage = (isBrowser && window.localStorage.getItem(key)) || ''
	const localCart = (localStorage && JSON.parse(localStorage)) || ''
	const [cart, setCart] = useState(localCart  || defaultValue)
	useEffect(() => {
		if ( localCart) {
			getOriginalproductData(localCart, setCart)				
		}

	}, [])
	useEffect(() => {
		windowGlobal.localStorage.setItem(key, JSON.stringify(cart))
	}, [cart, key])
	return [cart, setCart]
}

/**
 * getOriginalproductData - correct the product data from the localCart
 * @param {Object} localCart {lineItems: Array}
 * @param {Function} setCart - set the corrected cart
 * @returns {Void}
 */
const getOriginalproductData = async (localCart, setCart)=>{
	const url = "https://graphql.contentful.com/content/v1/spaces/"
	const headers = {
		Authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_DELIVERY_TOKEN}`,
		"Content-Type": "application/json",
	}
	const query = `
	query($sku_in: [String], $seo_title_in: [String], $slug_in: [String]) {
			productCollection(
				where: {
					active: true
					inStock: true

					OR: [
						{sku_in: $sku_in}, 
						{seoTitle_in: $seo_title_in}, 
						{slug_in: $slug_in} 
					]
					
				}
			) {
				total
				items {
					name: seoTitle
					_id
					sku
					price
					slug
					brand
					shippingClass
					categories {
					seoTitle}
					imagesCollection {
						items {
						url
						}
						}
				}
			}
		}`
	const variables = { sku_in: localCart.lineItems.map(item => item.sku), seo_title_in: localCart.lineItems.map(item => item.name), slug_in: localCart.lineItems.map(item => item.slug) }

	const response = await axios.post( `${url}${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master`, {query, variables}, {headers}).catch(error => { console.error(error) 
				return {data: {data: {productCollection: {items: []}}}, error: error}
	})
	if(response.error){
		return
	}
	const correctItems = response.data.data.productCollection.items.map((item)=>{
		if (!item.name 
			|| !item.sku 
			|| !item.slug 
			|| !item.price 
			|| !item.brand 
			|| !item.shippingClass 
			|| !item.categories?.seoTitle 
			|| !item.imagesCollection?.items?.length) {
			return null
		}
		return {
			name: item.name,
			sku: item.sku,
			slug: item.slug,
			price: Number(item.price * 100),
			brand: item.brand,
			shippingClass: item.shippingClass,
			category: item.categories.seoTitle,
			img: `${item.imagesCollection.items[0].url}?w=450&h=450&q=60"`,
		}			
		}
	).filter(item => item)
	
	const correctedCartItems = localCart.lineItems.map(item => {
		const product = correctItems.find(
			product =>
				product.sku === item.sku ||
				product.slug === item.slug ||
				product.name === item.name
		)
		if (!product) {
			return null
		}
		return {...item, ...product}
	}).filter(item => item)
	setCart((prev)=>({...prev, lineItems: correctedCartItems}))
	return
} 

const defaultCart = {
	lineItems: [],
	paymentMethod: { id: '', billing_details: {} },
	id: '',
	isPickUp: false,
}
/** CartContext - Provider and Consumer */
const CartContext = React.createContext([{}, () => {}])
/**
 * CartProvider - Wrapping the layout in context API goodness
 * @param {Object} props
 * @param {Node} props.children
 */
const CartProvider = ({ children }) => {
	/** set Line Items */
	const [cart, setCart] = useCartWithLocalStorage('cart', defaultCart)
	return (
		<CartContext.Provider value={[cart, setCart]}>
			{children}
		</CartContext.Provider>
	)
}
CartProvider.propTypes = {
	children: PropTypes.node.isRequired,
}
export { CartContext, CartProvider }
