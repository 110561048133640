import React from 'react';
import './swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import Img from 'gatsby-image'
import 'swiper/swiper.min.css';
import './nav.css';
export const SwiperComponent = ({ data }) => {
SwiperCore.use([Navigation]);
  const slides = data.map((item, index) => {
    const {text, img, footer} = item;
    return (
      <SwiperSlide key={`slide-${index}`} 
      style={{ boxSizing: 'border-box' }}>
        <div tw='h-full w-full flex flex-col'css={css`
            height: 60vh;
            min-height: 300px;
            max-height: 450px;
            `}>
          <div tw='h-full'>
              <Img {...img} tw="w-full h-full flex" loading="eager" 
               css={css`
                            img {
                              object-fit: contain !important;
                            }
                          `}
                          />
            </div>
        </div>
</SwiperSlide>
    );
  });

  return (
    <Swiper
  centeredSlides={true}
  loop={true}
  style={{ width: '100%', height: '100%', overflow: 'visible' }}
  breakpoints={{
    0: {
      slidesPerView: 1.2,
      spaceBetween: 8
    },
    768: {
      slidesPerView: 1.9,
      spaceBetween: 18
    },
  }}
  navigation
  speed={1000}
>
      {slides}
    </Swiper>
  );
};

SwiperComponent.propTypes = {
	data: PropTypes.objectOf(PropTypes.array),
	}

